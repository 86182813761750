import React, { useState, useEffect } from "react";
import { buildImageObj, cn, generateTrackingPath, getAppBase } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import Container from "../container";

import styles from "./platforms.module.css";

import target from "../../images/target-sm.svg";
import automate from "../../images/automate-sm.svg";
import notification from "../../images/notification.svg";

function Platforms({ title = null, tileOrder = null, primaryStyle = null, cta = true }) {
  const [registerPath, setRegisterPath] = useState("/register/");
  const appBase = getAppBase();

  const columns = [
    <React.Fragment>
      <img src={target} alt="Target Allocation" />
      <h3>Maintain Your Portfolio's Target Allocation</h3>
      <p>Set your allocation and step away.</p>
    </React.Fragment>,
    <React.Fragment>
      <img src={automate} alt="Automate" />
      <h3>Automate Your Investments</h3>
      <p>Rebalance in one click.</p>
    </React.Fragment>,
    <React.Fragment>
      <img src={notification} alt="Smart Alerts" />
      <h3>Never Miss a Thing With Smart Alerts</h3>
      <p>Get notified when you need to take action.</p>
    </React.Fragment>,
  ];

  const order = tileOrder ? tileOrder : [0, 1, 2];

  useEffect(() => {
    setRegisterPath(generateTrackingPath("/register/"));
  }, []);

  return (
    <section className={primaryStyle ? primaryStyle : styles.platforms}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.copyContainer}>
            <h2>{title ? title : "How Passiv can transform your investing"}</h2>
            <div>
              <p>
                Investing can be so rewarding, but also time consuming and stressful. Passiv is here
                to save you time and make investing easy.
              </p>
            </div>
          </div>
        </div>
        <div className={cn(styles.col3, styles.platformCols)}>
          <div className={cn(styles.innerCol, styles.tile)}>
            <div className={styles.tileContainer}>{columns[order[0]]}</div>
          </div>
          <div className={cn(styles.innerCol, styles.tile)}>
            <div className={styles.tileContainer}>{columns[order[1]]}</div>
          </div>
          <div className={cn(styles.innerCol, styles.tile)}>
            <div className={styles.tileContainer}>{columns[order[2]]}</div>
          </div>
        </div>
        {cta ? (
          <div className={styles.button}>
            <a
              className={cn(styles.btn1, styles.registerBtn)}
              href={`https://${appBase}${registerPath}`}
            >
              Try Passiv for Free
            </a>
          </div>
        ) : null}
      </Container>
    </section>
  );
}

export default Platforms;
